import React from "react";

import Form from "react-bootstrap/Form";

import { WidgetProps } from "@rjsf/core";
import WidgetLabel from "./WidgetLabel";

export const RadioWidget = ({
    id,
    schema,
    options,
    value,
    required,
    disabled,
    readonly,
    label,
    rawErrors,
    onChange,
    onBlur,
    onFocus,
}: WidgetProps) => {
    const { enumOptions, enumDisabled } = options;

    const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
        onChange(schema.type === "boolean" ? value !== "false" : value);

    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);

    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

    const inline = Boolean(options && options.inline);
    const isInvalid = rawErrors && rawErrors.length > 0;

    return (
        <fieldset role="radiogroup" aria-required={required}>
            {label && <WidgetLabel id={id} isFieldset label={label} schema={schema} required={required} rawErrors={rawErrors} />}
            {(enumOptions as any).map((option: any, index: number) => {
                const itemDisabled = Array.isArray(enumDisabled) && enumDisabled.indexOf(option.value) !== -1;
                const checked = option.value === value;

                return (
                    <Form.Check
                        inline={inline}
                        label={option.label}
                        id={`${id}-${option.value}`}
                        key={index}
                        name={id}
                        type="radio"
                        disabled={disabled || itemDisabled || readonly}
                        checked={checked}
                        value={option.value}
                        isInvalid={isInvalid}
                        aria-describedby={isInvalid && index === 0 ? `${id}-error` : undefined}
                        aria-invalid={isInvalid && index === 0}
                        onChange={_onChange}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                    />
                );
            })}
        </fieldset>
    );
};

export default RadioWidget;
